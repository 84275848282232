import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { GAME_URL } from '../constants/constants';
import '../assets/css/App.css';

const GameComponent = forwardRef((props, ref) => {
  const [gameLoaded, setGameLoaded] = useState(false);
  const [gameVisible, setGameVisible] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    // Preload the game when the component mounts
    preloadGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preloadGame = () => {
    // Set the source of the iframe to load the game
    if (iframeRef.current && !gameLoaded) {
      iframeRef.current.src = GAME_URL;
      // Listen to the iframe's load event to determine when the game has finished loading
      iframeRef.current.onload = () => {
        // Once the game is loaded, set gameLoaded to true
        setGameLoaded(true);
      };
    }
  };

  const toggleVisibility = () => {
    // Toggle the visibility of the game by toggling the state
    console.log("Toggle visibility function called"); // Add this line for debugging
    setGameVisible(!gameVisible);
  };

  const toggleFullScreen = () => {
    const iframe = iframeRef.current;

    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) { // Firefox
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari and Opera
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) { // IE/Edge
        iframe.msRequestFullscreen();
      }
    }
  };

  // JavaScript code in React for Fadi to review
  const sendMessageToGodot = () => {
    const iframe = iframeRef.current;
    console.log("iframeRef.current from GameComponent",iframeRef.current)
    // Fadi here you can test sending a json object to Godot in the location of message
    iframe.contentWindow.postMessage({ godot: true, message: 'Hello from React!' }, '*');
    console.log('Message sent to Godot:', 'Hello from React!');
  };


// Use the forwarded ref
useImperativeHandle(ref, () => ({
  toggleVisibility: toggleVisibility,
  iframe: iframeRef.current,
}));

  
  return (
    <div className='game-container'>
      <iframe
        title="scenario-creator-game"
        width="1280"
        height="720"
        ref={iframeRef}
        style={{ overflow: 'hidden', display: gameVisible ? 'block' : 'none'  }}
        allowFullScreen
      ></iframe>
      {gameVisible && (
        <button className='btn orange-color circule' onClick={toggleFullScreen}>Full Screen Mode</button>
      )}
      {/* test message to godot */}
      {/* <div className="button-container">
          <button className='btn orange-color button-spacing' onClick={sendMessageToGodot}>Send Message to Godot</button>
      </div> */}

    </div>
  );
});

export default GameComponent;
