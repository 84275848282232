import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, LoadScript, DirectionsRenderer, Marker } from '@react-google-maps/api';
import { Maneuver, RoadLayout, RoadId, DefaultTileLength, TrafficDirections, RoundaboutExits, TurningAngleThreshold ,GoogleMap_API_KEY} from './MapsConstants';
import { validateDistance, validateTilesNumber } from './Validation';
import { EarthPoint, EarthLine } from './GeoUtils';

const Map = ({ onJsonStringChange, onError }) => {

    const [directions, setDirections] = useState(null);
    const [origin, setOrigin] = useState({ lat: 52.387349287991924, lng: -1.5702021353560254 });
    const [destination, setDestination] = useState({ lat: 52.38801261590363, lng: -1.560911230105585 });
    const [mapLoaded, setMapLoaded] = useState(false);
    const travelMode = 'DRIVING';

    // Function to handle directions
    const handleDirections = useCallback((response) => {
        onError("")
        if (response && response.status === 'OK') {
            setDirections(response);
            console.log('Directions Response:', response); // Log the entire directions response to the console

            // Get total Distance 

            let dist = response.routes[0].legs[0].distance.text; // e.g., '75 m' or '1.6 km'
            // validateDistance(dist)
            if (!validateDistance(dist, onError)) {
                console.log("Validation failed. Halting further processing.");
                return; // Stop further execution if validation fails
            }

            // Log each step with instructions
            const stepsData = response.routes[0].legs[0].steps.map((step, index) => ({
                stepNumber: index,
                fullInstructions: step.instructions,
                instructions: step.instructions.replace(/<\/?b>/g, ''), // Remove HTML tags
                maneuver: step.maneuver || 'N/A', // Handle case where maneuver might be undefined
                distance: step.distance.text,
                lat_lngs: step.lat_lngs
            })
            );

            let x = 0, y = 0, roadId = "", RoadLayoutTile = {};
            let currentDirection = "", trafficDirection = TrafficDirections.LHT, placedTileNumber = 0;

            //Define a function to add a tile to the RoadLayoutTile object
            function addTile(x, y, roadId) {

                if (!validateTilesNumber(placedTileNumber, onError)) {
                    console.log("Validation failed. Halting further processing.");
                    return;
                }

                RoadLayoutTile[`(${x},${y})`] = roadId;
                placedTileNumber++;
            }

            // Function to change tile coordinates based on heading
            function tileCoodrinateChange(initialDirection) {
                let deltaX = 0, deltaY = 0;
                switch (initialDirection) {
                    case RoadLayout.HeadSouth: deltaY = +1; break;
                    case RoadLayout.HeadNorth: deltaY = -1; break;
                    case RoadLayout.HeadWest: deltaX = -1; break;
                    case RoadLayout.HeadEast: deltaX = 1; break;
                }
                return { deltaX, deltaY };
            }

            const getNextDirection = (initialDirection, maneuver) => {
                let nextDirection = initialDirection;
                if (maneuver === Maneuver.TurnRight || maneuver === Maneuver.RoundaboutRight) {
                    nextDirection = getTurnDirection(initialDirection, 1);
                } else if (maneuver === Maneuver.TurnLeft || maneuver === Maneuver.RoundaboutLeft) {
                    nextDirection = getTurnDirection(initialDirection, -1);
                }
                return nextDirection;
            };

            // Helper function to calculate turn direction
            const getTurnDirection = (direction, clockwise) => {
                const directions = [RoadLayout.HeadSouth, RoadLayout.HeadWest, RoadLayout.HeadNorth, RoadLayout.HeadEast];
                const index = directions.indexOf(direction);
                return directions[(index + clockwise + 4) % 4];
            };

            // Get the road ID for a straight road based on the direction of travel
            function getRoadIDStraight(direction) {
                return (direction === RoadLayout.HeadSouth || direction === RoadLayout.HeadNorth)
                    ? RoadId.STRAIGHT_VERTICAL
                    : RoadId.STRAIGHT_HORIZONTAL;
            };

            // Get the road ID for a turn based on the direction of travel and the maneuver
            const getRoadIDTurn = (direction, maneuver) => {
                let newDirection = getNextDirection(direction, maneuver);
                return (direction === RoadLayout.HeadNorth || newDirection === RoadLayout.HeadSouth)
                    ? (direction === RoadLayout.HeadWest || newDirection === RoadLayout.HeadEast) ? RoadId.SouthEast : RoadId.SouthWest
                    : (direction === RoadLayout.HeadSouth || newDirection === RoadLayout.HeadNorth)
                        ? (direction === RoadLayout.HeadWest || newDirection === RoadLayout.HeadEast) ? RoadId.NorthEast : RoadId.NorthWest
                        : undefined;
            };



            //Takes in a line array and converts it to a maneuver array
            function convertLinesToTiles(lineArray) {
                //TODO move this into a function
                let currentHeading = lineArray[0].heading;
                let lastTurnHeading = null;
                let runningDistanceCounter = 0;
                let tileArray = new Array();
                for (let i = 0; i < lineArray.length; i++) {
                    let distance = lineArray[i].length;
                    runningDistanceCounter += distance;
                    let angle = Math.atan2(Math.sin(lineArray[i].heading - currentHeading), Math.cos(lineArray[i].heading - currentHeading));
                    if (lineArray[i].heading != currentHeading && lastTurnHeading != currentHeading && tileArray.length > 0 && i != lineArray.length - 1) {
                        // If the angle between the current point and the previous point is greater than the threshold accounting for periodicity
                        if (Math.abs(angle) >= TurningAngleThreshold) {
                            if (angle < 0) {
                                tileArray.push(Maneuver.TurnRight);
                            }
                            else {
                                tileArray.push(Maneuver.TurnLeft);
                            }
                            currentHeading = lineArray[i].heading;
                            lastTurnHeading = currentHeading;
                        }
                        //if turn completed start new turn
                    } else if (lastTurnHeading != null && Math.abs(angle) >= Math.PI / 2 - TurningAngleThreshold) {
                        currentHeading = lineArray[i].heading;
                        lastTurnHeading = null;
                    }
                    // If the running distance counter is greater than the length of a tile, add straight road tiles unil the counter is less than the length of a tile
                    for (let j = 1; j <= runningDistanceCounter / DefaultTileLength; j++) {
                        tileArray.push(Maneuver.Empty);
                    }
                    runningDistanceCounter = runningDistanceCounter % DefaultTileLength;
                }
                if (runningDistanceCounter > 0) {
                    tileArray.push(Maneuver.Empty);
                }

                return tileArray;
            }

            response.routes[0].legs[0].steps.forEach(step => {
                step.instructions = step.instructions.replace(/<\/?b>/g, '')
                var amendedInstructions = step.instructions.split(/[-\s]/)[0] + ' ' + step.instructions.split(/[-\s]/)[1];
                // Set directions based on the head direction
                if (currentDirection == "") {
                    switch (amendedInstructions) {
                        case RoadLayout.HeadSouth:
                            currentDirection = RoadLayout.HeadSouth;
                            break;
                        case RoadLayout.HeadNorth:
                            currentDirection = RoadLayout.HeadNorth;
                            break;
                        case RoadLayout.HeadWest:
                            currentDirection = RoadLayout.HeadWest;
                            break;
                        case RoadLayout.HeadEast:
                            currentDirection = RoadLayout.HeadEast;
                            break;
                        default:
                            currentDirection = RoadLayout.HeadSouth;
                            break;
                    }
                }

                let lineArray = new Array(step.lat_lngs.length - 1);
                let lastPoint = new EarthPoint(step.lat_lngs[0].lat(), step.lat_lngs[0].lng());
                let newPoint = null
                for (let i = 1; i < step.lat_lngs.length; i++) {
                    newPoint = new EarthPoint(step.lat_lngs[i].lat(), step.lat_lngs[i].lng());
                    lineArray[i - 1] = new EarthLine(lastPoint, newPoint);
                    lastPoint = newPoint;
                }

                let tileArray = convertLinesToTiles(lineArray)

                // console.log("tileArray", tileArray);

                // ******** Handle Turning ************
                if ((step.maneuver == Maneuver.TurnRight || step.maneuver == Maneuver.RoundaboutRight || step.maneuver == Maneuver.TurnLeft || step.maneuver == Maneuver.RoundaboutLeft)
                    && !(step.instructions.includes("straight") || step.instructions.includes("Straight"))) {
                    // Check for junction tile type
                    var trueManeuver = step.maneuver;
                    var junctionTileID = "";
                    if (step.maneuver == Maneuver.RoundaboutLeft || amendedInstructions == Maneuver.RoundaboutRight) {
                        junctionTileID = RoadId.Roundabout;
                        if ((step.instructions.includes(RoundaboutExits.one) && trafficDirection == TrafficDirections.LHT) || (step.instructions.includes(RoundaboutExits.three) && trafficDirection == TrafficDirections.RHT)) {
                            trueManeuver = Maneuver.RoundaboutLeft;
                        } else if (step.instructions.includes(RoundaboutExits.two)) {
                            trueManeuver = Maneuver.Empty;
                        } else {
                            trueManeuver = Maneuver.RoundaboutRight;
                        }
                    } else {
                        junctionTileID = RoadId.Crossroad;
                    }
                    // Determine the next direction after the intersection
                    deltaCoords = tileCoodrinateChange(currentDirection, trueManeuver);
                    // Add the intersection tile
                    x = x + deltaCoords.deltaX;
                    y = y + deltaCoords.deltaY;
                    addTile(x, y, junctionTileID);
                    // Add a straight road tile after the intersection
                    currentDirection = getNextDirection(currentDirection, trueManeuver);
                }
                // ******** END Handling Turning *************  
                //Handle the rest of the road            
                var containsRoundabout = false // step.instructions.includes("roundabout");
                var roundaboutTurns = 0;
                if (containsRoundabout) {
                    roundaboutTurns = 2;
                }

                for (let i = 0; i < tileArray.length; i++) {
                    // Calculate changes in x and y based on the current direction
                    var deltaCoords = tileCoodrinateChange(currentDirection, tileArray[i]);
                    // Choose ROADID based on the current direction
                    if (roundaboutTurns > 0 && (tileArray[i] == Maneuver.TurnLeft || tileArray[i] == Maneuver.TurnRight)) {
                        if (roundaboutTurns % 2 == 0) {
                            roadId = RoadId.Roundabout;
                        } else {
                            roadId = getRoadIDStraight(currentDirection);
                        }
                        roundaboutTurns--;
                    } else if (tileArray[i] == Maneuver.TurnRight || tileArray[i] == Maneuver.TurnLeft) {
                        roadId = getRoadIDTurn(currentDirection, tileArray[i]);
                        currentDirection = getNextDirection(currentDirection, tileArray[i]);
                    } else {
                        roadId = getRoadIDStraight(currentDirection);
                    }
                    // For each tile that can fit into the distance, add a straight road tile
                    x = x + deltaCoords.deltaX;
                    y = y + deltaCoords.deltaY;
                    addTile(x, y, roadId);

                }
                // ******** END Handling Head *************
            });
            // Log the steps in JSON format
            // console.log(JSON.stringify(stepsData, null, 2));
            // console.log("RoadLayoutTile", RoadLayoutTile);

            // Wrap it in a data object
            const data = { data: RoadLayoutTile };

            // Convert to JSON string
            const jsonString = JSON.stringify(data);
            console.log("jsonString", jsonString);
            if (onJsonStringChange) {
                onJsonStringChange(jsonString);
            }
        } else {
            console.error('Error fetching directions', response);
        }
    }, [onJsonStringChange, onError]);

    // Effect to fetch directions when origin, destination, or travel mode changes
    useEffect(() => {
        if (origin && destination && mapLoaded) { // Ensure the map is loaded
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: new window.google.maps.LatLng(origin.lat, origin.lng),
                    destination: new window.google.maps.LatLng(destination.lat, destination.lng),
                    travelMode: travelMode,
                },
                handleDirections
            );
        }
    }, [origin, destination, travelMode, handleDirections, mapLoaded]);

    return (
        <LoadScript
            googleMapsApiKey={GoogleMap_API_KEY}
            onLoad={() => setMapLoaded(true)}
        >
            <div>

                <GoogleMap
                    id="direction-example"
                    mapContainerStyle={{ height: '400px', width: '100%' }}
                    zoom={8}
                    center={origin}
                    options={{
                        fullscreenControl: false, // Disable fullscreen control
                    }}
                >
                    {directions && (
                        <DirectionsRenderer
                            options={{
                                directions: directions,
                                suppressMarkers: true, // Prevent default markers from showing
                            }}
                        />
                    )}
                    {/* Draggable Markers */}
                    <Marker
                        position={origin}
                        label={{
                            text: "A",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold",
                        }}

                        draggable={true}
                        onDragEnd={(e) => {
                            const newOrigin = { lat: e.latLng.lat(), lng: e.latLng.lng() }; // Update origin position
                            setOrigin(newOrigin);
                        }}
                    />
                    <Marker
                        position={destination}
                        label={{
                            text: "B",
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: "bold",
                        }}
                        draggable={true}
                        onDragEnd={(e) => {
                            const newDestination = { lat: e.latLng.lat(), lng: e.latLng.lng() }; // Update destination position
                            setDestination(newDestination);
                        }}
                    />
                </GoogleMap>
            </div>
        </LoadScript>
    );
};

export default Map;
