export const Map_Version = '1.0';
export const GoogleMap_API_KEY = 'AIzaSyBM_axZcaFMhQm3OxmT_URxDu3gvGavRL8';

export const Unit = {
    Meter: "m",
    Kilometer: "km"
};

export const Maneuver = {
    TurnRight: "turn-right",
    RoundaboutRight: "roundabout-right",
    TurnLeft: "turn-left",
    RoundaboutLeft: "roundabout-left",
	RoundaboutContinue: "roundabout-continue",
    Empty: ""
}
export const RoadLayout = {
    HeadSouth: "Head south",
    HeadNorth: "Head north",
    HeadWest: "Head west",
    HeadEast: "Head east",
    TurnRight: "Turn right",
    TurnLeft: "Turn left",

}
// Define the RoadId enum
export const RoadId = {
    STRAIGHT_HORIZONTAL: 0,
    STRAIGHT_VERTICAL: 1,
    Horizontal_Tjunction_Top: 8,
    Horizontal_Tjunction_Bottom: 11,
    Crossroad: 9,
    Roundabout: 10,
	NorthEast: 3,
	SouthWest: 4,
	SouthEast: 6,
	NorthWest: 7
};

export const DefaultTileLength = 50;

export const TurningAngleThreshold = Math.PI/2.5;

export const EarthRadius = 6371000;

export const TrafficDirections = {
    LHT: "LHT",
    RHT: "RHT"
};

export const RoundaboutExits = {
    one: "1st",
    two: "2nd",
    three: "3rd",
    four: "4th",
}

export const PlacedTileLimit = 100;

// km
export const SupportedDistanceLimit = 1.2;