import { SupportedDistanceLimit, PlacedTileLimit } from "./MapsConstants";

export const validateDistance = (distance, onError) => {
    // Extract the numeric value and unit from the text
    let matches = distance.match(/^([\d.]+)\s*(m|km)$/);

    if (matches) {
        let value = parseFloat(matches[1]); // Extract the numeric value
        let unit = matches[2]; // Extract the unit (either 'm' or 'km')

        // Convert to kilometers if the unit is meters
        let distanceInKm = unit === "m" ? value / 1000 : value;

        // Check if the distance is more than 1.2 km
        if (distanceInKm > SupportedDistanceLimit) {
            const errorMessage = `Error: The system currently supports distances up to ${SupportedDistanceLimit} km. Actual distance: ${distanceInKm} ${unit}`;
            console.error(errorMessage);
            if (errorMessage) {
                onError(errorMessage);
                return false;
            }

        } else {
            console.log(`Distance is ${SupportedDistanceLimit} km or less. Actual distance: ${distanceInKm} ${unit}`);
        }
    } else {

        const errorMessage = `Error: An error occurred while validating the distance. Please contact the administrator for assistance.`;
        onError(errorMessage);
        return false;
        // console.error("Unexpected distance format: " + distance);
    }
    return true;
}



export const validateTilesNumber = (placedTileNumber, onError) => {
    if (placedTileNumber >= PlacedTileLimit) {

        const errorMessage = `Error: The system currently supports tiles number up to ${PlacedTileLimit}.`;
        console.error(errorMessage);
        if (errorMessage) {
            onError(errorMessage);
            return false;
        }
    }
    return true;
}