// utils/GeoUtils.js

import {  EarthRadius } from './MapsConstants';

export class EarthPoint {
    constructor(lat, long) {
        this.lat = lat * Math.PI / 180;
        this.long = long * Math.PI / 180;
    }

    getDistance(p) {
        return Math.acos(Math.sin(p.lat) * Math.sin(this.lat) + Math.cos(p.lat) * Math.cos(this.lat) * Math.cos(p.long - this.long)) * EarthRadius;
    }
}

export class EarthLine {
    constructor(startPoint, endPoint) {
        this.startPoint = startPoint;
        this.endPoint = endPoint;
        this.setHeading();
        this.length = startPoint.getDistance(endPoint);
    }

    setHeading() {
        let deltax = Math.cos(this.endPoint.lat) * Math.sin(this.endPoint.long - this.startPoint.long);
        let deltay = Math.cos(this.startPoint.lat) * Math.sin(this.endPoint.lat) - Math.sin(this.startPoint.lat) * Math.cos(this.endPoint.lat) * Math.cos(this.endPoint.long - this.startPoint.long);
        this.heading = Math.atan2(deltay, deltax);
    }
}
