import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import MapComponent from '../components/Map/GoogleMap';

function ModalMap({ iframeRef }) {

    const [showModal, setShowModal] = useState(false);
    const [jsonString, setJsonString] = useState('');
    const [error, setError] = useState('');
    const handleClose = () => {
        setShowModal(false);  // Close modal
    };

    const handleOpenModal = () => {
        // Check if fullscreen mode is active
        if (document.fullscreenElement) {
            // Exit fullscreen mode
            document.exitFullscreen().then(() => {
                console.log('Exited fullscreen mode');
                setShowModal(true); // Open modal after exiting fullscreen
            }).catch((err) => {
                console.error('Error while exiting fullscreen mode:', err);
            });
        } else {
            setShowModal(true); // Open modal directly if not in fullscreen
        }
    };


    // JavaScript code in React for Fadi to review
    const sendMessageToGodot = () => {
        const iframe = iframeRef.current.iframe;
        if (iframe && iframe.contentWindow) {
            iframe.contentWindow.postMessage({ godot: true, message: jsonString }, '*');
            console.log('Message sent to Godot:', 'Hello from React!', "jsonString :", jsonString);
            handleClose();
        } else {
            console.log('Iframe is not available or not loaded.');
        }
    };


    useEffect(() => {
        // Listen for messages from Godot
        window.addEventListener('message', (event) => {
            // checks if message begins with 'Godot:'
            if (event.data.godot === true) {// && typeof event.data === 'string' && event.data.indexOf('Hello from Godot!') === 0) {
                // Printing Data received from Godot
                console.log('Data received from Godot:', event.data);
                // Checks if open_google_maps command is received
                if (event.data.open_google_maps) {
                    handleOpenModal();

                    console.log('Command received from Godot - open_google_maps:', event.data.open_google_maps);
                }
                // Checks if message is received
                if (event.data.message) {
                    console.log('Message received from Godot:', event.data.message);
                }
                // Checks if check_admin command is received
                if (event.data.check_admin) {
                    console.log('Command received from Godot - check_admin:', event.data.check_admin);
                }
            }
        });
    }, []);
    return (
        <div >
            <Modal show={showModal} onHide={handleClose} size="lg">
                <Modal.Header closeButton className='orange-background-color-text-white'>
                    <Modal.Title >
                        Import Google Map to Safety Pool™ Studio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <MapComponent onJsonStringChange={(json) => setJsonString(json)} onError={(err) => setError(err)} />
                </Modal.Body>
                <Modal.Footer>
                    {/* Display error message if it's not empty */}
                    {error && (
                        <div className='alert alert-danger ' style={{ marginBottom: '10px', width: '100%' }}>
                            <b> {error}</b>
                        </div>
                    )}

                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Button
                            variant="btn orange-color"
                            style={{ width: '200px' }}
                            onClick={sendMessageToGodot}
                            disabled={!!error || !jsonString} // Disable button if error or jsonString is empty


                        >
                            Import Google Map
                        </Button>
                        <Button
                            variant="btn btn-secondary"
                            style={{ width: '100px' }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalMap;