import '../assets/css/index.css';
import React, { useState, useRef, lazy, Suspense, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FeaturedIn from '../components/Featured-in';
import GameApp from '../pages/GameApp'
import { useNavigate } from 'react-router-dom';
import Vision from '../components/Vision';
import GameComponent from "../components/GameComponent";
import LandingPageImg from '../images/FrontPage/banner.mp4';

import { MotionAnimate } from 'react-motion-animate';
import axisImage from '../images/axis.png';
import MapComponent from '../components/Map/GoogleMap';

import ModalMap from "../components/ModalMap"
const TechnologyPlatform = lazy(() => import('../components/TechnologyPlatform'));
const Card = lazy(() => import('../components/Card'));
export default function Landing() {

    const [gameStarted, setGameStarted] = useState(false);
    const gameComponentRef = useRef(null);
    const handleGetStarted = () => {
        setGameStarted(true);
    }

    const navigate = useNavigate();


    return (
        <>
            if
            <div>
                {/* <Row>
                    <Col xs={9} md={9}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <MapComponent />
                        </div>
                    </Col>
                    <Col xs={3} md={3}>
                        <img src={axisImage} style={{ width: '100%', height: 'auto' }} />
                    </Col>

                </Row> */}
                <hr />
                <div>
                    <Container>

                        {!gameStarted ? (
                            <Row >

                                {/* Make the text in the centre */}
                                <Row >
                                    <div >
                                        <div style={{ textAlign: 'center', backgroundColor: '#20222E' }} className='font-family-RalewayExtraBold' >
                                            <br />
                                            <p style={{ fontSize: '32px' }} className='orange-color-font '>
                                                Democratising Safety
                                            </p>
                                            <p style={{ fontSize: '23px' }} className='text-white'>
                                                A public platform for public users to help ensure the safety of Automated Vehicles.
                                            </p>

                                            <br /> <br />

                                        </div>
                                        <br /> <br />
                                    </div>
                                </Row>

                                <Col xs={12} md={5}>


                                    <br /><br />
                                    <MotionAnimate>
                                        <div className="main-text orange-color-font">
                                            Create your own scenario
                                        </div>
                                    </MotionAnimate>
                                    <br />
                                    <div className="normal-text">
                                        Unlock your creative potential with Safety Pool™ Studio, a powerful tool that enables you to effortlessly design scenarios that align with your vision, providing a seamless and intuitive experience                                    </div>
                                    <br />

                                    <div className="button-container">
                                        <button className='btn orange-color button-spacing' onClick={() => { handleGetStarted(); gameComponentRef.current.toggleVisibility(); }}>Get started</button>
                                        <button onClick={() => navigate("/tutorial")} type="button" className='btn black-color button-spacing' >Watch Tutorial </button>
                                    </div>

                                </Col >

                                <Col xs={12} md={7}>
                                    <video
                                        src={LandingPageImg}
                                        autoPlay
                                        loop
                                        muted
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                </Col>
                            </Row>

                        ) : (
                            <Row>
                                <Col>
                                    <div>
                                        <GameApp />
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <div className="gamecontainer">
                            <GameComponent ref={gameComponentRef} />
                        </div>
                        <br /> <br />



                        <Row >
                            <div className='centered-text orange-color-font'>
                                <b> Supported by </b>
                            </div>
                            <FeaturedIn type="Home" />
                        </Row>
                    </Container>
                    <Container>
                        <br />
                        <br />

                        <Row>
                            <Vision />
                        </Row>
                    </Container>
                    <br />
                    <br />
                    <div>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Row className='dark-back-ground-color '>
                                <Card />
                            </Row>
                        </Suspense>

                    </div>
                    <Container>
                        <br />
                        <br />

                        <div >
                            <Suspense fallback={<div>Loading...</div>}>
                                <TechnologyPlatform />
                            </Suspense>
                        </div>
                    </Container>

                </div>
            </div>
            <ModalMap iframeRef={gameComponentRef} />

      
        </>

    )

}